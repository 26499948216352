import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

export const SalirUsuario = () => {
    
    const UrlGral = localStorage.getItem("UrlGral")

    const [redirect, setRedirect] = useState(null);

    useEffect(async () => {             
        var id_salir = localStorage.getItem('user_id');
        let res = null;
        
        try{
            res = await axios.get(UrlGral+"clientes/cerrar-sesion/?salirsesion="+id_salir);
        }catch(err){
            res = null;
            console.log(err);
            window.location.reload();
        }
        if(res){
            //localStorage.setItem('user_id', null);
            console.log(res)
            localStorage.removeItem('user_id');
            localStorage.removeItem('tipo');
            localStorage.removeItem('llave');
            localStorage.removeItem('user_lista');
            setRedirect('/login');  
            window.location.reload();           
        } 
    }, []);


    if(redirect){
        
        return <Redirect to={redirect}/>
    }

    return null;

}