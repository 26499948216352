import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { AppMenu } from './AppMenu';

import { CatalogoComponente } from './components/Catalogo';
import { ClientesComponente } from './components/verclientes';
import { VerPedidosComponentes } from './components/verpedidos';
import { VerMarcasComponente } from './components/vermarcas';
/* import { VerProductosComponente } from './components/verproductos'; */
import { MarcasComponente } from './components/Marcas';
import { Login } from './components/Login';
import { MisPedidos } from './components/MisPedidos';
import { SalirUsuario } from './components/Salir';


import PrimeReact from 'primereact/utils';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './estilos.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';

const App = () => {

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('dark')
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const sidebar = useRef();
    let menuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);


    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    let menu = [
        /* { label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login' } , */
    ];

    if(localStorage.getItem('user_id')){
        if(localStorage.getItem('tipouser') == "ADMIN"){
            menu = [
                { label: 'Clientes', icon: 'pi pi-fw pi-user', to: '/verclientes' } ,
                { label: 'Pedidos', icon: 'pi pi-fw pi-list', to: '/verpedidos' },
                { label: 'Marcas', icon: 'pi pi-fw pi-list', to: '/vermarcas' },
                /* { label: 'Productos', icon: 'pi pi-fw pi-list', to: '/verproductos' }, */
                { label: 'Salir', icon: 'pi pi-fw pi-sign-out', to: '/salir' },
            ];
        } else {
            menu = [
                { label: 'Catalogo', icon: 'pi pi-fw pi-table', to: '/catalogo' } ,
                { label: 'Mis Pedidos', icon: 'pi pi-fw pi-list', to: '/mispedidos' },
                { label: 'Marcas', icon: 'pi pi-fw pi-file', to: '/marcas' },
                { label: 'Salir', icon: 'pi pi-fw pi-sign-out', to: '/salir' },                
            ];
        }
         
    } else {
        menu = [
            { label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login' } ,
        ];
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    }

    const logo = layoutColorMode === 'dark' ? 'assets/img/logo.png' : 'assets/img/logo.png';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-logo img-logo verescritorio">
                        <img alt="Logo" src={logo} />
                    </div>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                </div>
            </CSSTransition>

            <div className="layout-main">
                <Route path="/" exact component={Login} />
                <Route path="/catalogo" component={CatalogoComponente} />
                <Route path="/verclientes" component={ClientesComponente} />
                <Route path="/verpedidos" component={VerPedidosComponentes} />
                <Route path="/vermarcas" component={VerMarcasComponente} />
                {/* <Route path="/verproductos" component={VerProductosComponente} /> */}
                <Route path="/marcas" component={MarcasComponente} />
                <Route path="/login" component={Login} />                
                <Route path="/mispedidos" component={MisPedidos} />
                <Route path="/salir" component={SalirUsuario} />                
            </div>

        </div>
    );

}

export default App;
