import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Redirect } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Productos } from '../service/Productos';
import { MarcasServicios } from '../service/Marcas';
import { RubrosServicios } from '../service/Rubros';
import { ItemsActual } from '../service/ItemsActual';
import { ActualizaCarrito } from '../service/ActualizaCarrito';

import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';

export const CatalogoComponente = () => {

    let emptyProduct = {
        id: null,
        nombre: '',
        img: '',
        dercripcion: '',
        marca: null,
        codigo: '',
        precio: '',
        precio_siniva: '',
        ids: 0,
        rubro: '',
        cantidad: ''
    };
    
    const UrlGral = localStorage.getItem("UrlGral")
    const userLista = localStorage.getItem("user_lista")


    const [products, setProducts] = useState(null);
    const [marcas, setMarcas] = useState(null);
    const [rubros, setRubros] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    /* const [selectedCustomers1, setSelectedCustomers1] = useState(emptyProduct); */
    const [globalFilter1, setGlobalFilter1] = useState('');
    const dt = useRef(emptyProduct);
    const [redirect, setRedirect] = useState(null);
    const toast = useRef();
    const [visibleRight, setVisibleRight] = useState(false);
    const [itemsactual, setItemsActual] = useState(null);
    const [selectedItemsActual, setSelectedItemsActual] = useState(null);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [displayConfirmationVaciar, setDisplayConfirmationVaciar] = useState(false);
    const [displayConfirmationGenerado, setDisplayConfirmationGenerado] = useState(false);
    const [displayConfirmationVaciado, setDisplayConfirmationVaciado] = useState(false);

    
    /* const producto = new Productos();
    producto.getProducts().then(data => setProducts(data));  */ 
    /* console.log("USE DANTE"); */
    /* console.log(globalFilter2) */
    useEffect(() => {   
        
        const producto = new Productos();
        producto.getProducts().then(data => setProducts(data)); 
        const rubro = new RubrosServicios();
        rubro.getRubro().then(data => setRubros(data));  
        const marca = new MarcasServicios();
        marca.getMarca().then(data => setMarcas(data));        
        const actualizacarrito = new ActualizaCarrito();
        actualizacarrito.getActualizaCarrito().then(console.log("ACTUALIZADA"));
        const itemactual = new ItemsActual();
        itemactual.getActual().then(data => setItemsActual(data));       
        if(!localStorage.getItem('user_id')){
        setRedirect('/login');
        }   
    }, []); 

    /* console.log(localStorage.getItem("tipo"))
    console.log(localStorage.getItem("user_id")) */
    /* console.log("Productos:",products)
    console.log("Rubros:",rubros)
    console.log("Marcas:",marcas)
    console.log("---------------------------------------------------------") */
function campoBusquedaDeshabilitar (){
    var buscadolimpiar = document.getElementById("buscadorporcodigo");
        buscadolimpiar.value = "";
        buscadolimpiar.disabled = true;
        setGlobalFilter1('')
        var iconobuscar = document.getElementById("iconobuscar");
        iconobuscar.classList.remove("pi-search")
        iconobuscar.classList.add("pi-spin")
        iconobuscar.classList.add("pi-spinner")
        var marcaselect = document.getElementById("selectmarca");
        marcaselect.disabled = true;
        var rubroselect = document.getElementById("selectrubro");
        rubroselect.disabled = true;
        
}

function campoBusquedaHabilitar (){
    var buscadolimpiar = document.getElementById("buscadorporcodigo");
        buscadolimpiar.value = "";
        buscadolimpiar.disabled = false;
        var iconobuscar = document.getElementById("iconobuscar");        
        iconobuscar.classList.remove("pi-spin")
        iconobuscar.classList.remove("pi-spinner")
        iconobuscar.classList.add("pi-search")
        var marcaselect = document.getElementById("selectmarca");
        marcaselect.disabled = false;
        var rubroselect = document.getElementById("selectrubro");
        rubroselect.disabled = false;
}

    const handleChangeMarca = (event) => {  
        campoBusquedaDeshabilitar();
        localStorage.setItem('marcafiltrada', event.target.value)
        if(localStorage.getItem('marcafiltrada') !== "1" && localStorage.getItem('rubrofiltrado') !== "1"){
            class CargarMarcasFiltro{
                getMarcaFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarambos=1&marcaambos='+event.target.value+'&rubroambos='+localStorage.getItem('rubrofiltrado')+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }              
            const marcaFiltro = new CargarMarcasFiltro();
            marcaFiltro.getMarcaFiltro().then(data => setProducts(data));            
        } else if(localStorage.getItem('rubrofiltrado') === "1" && localStorage.getItem('marcafiltrada') !== "1"){
            class CargarMarcasFiltro{
                getMarcaFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarmarca=1&marca='+event.target.value+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }        
            const marcaFiltro = new CargarMarcasFiltro();
            marcaFiltro.getMarcaFiltro().then(data => setProducts(data)); 
        } else if(localStorage.getItem('marcafiltrada') === "1" && localStorage.getItem('rubrofiltrado') !== "1"){
            class CargarMarcasFiltro{
                getMarcaFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarambos=1&marcaambos='+event.target.value+'&rubroambos='+localStorage.getItem('rubrofiltrado')+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }        
            const marcaFiltro = new CargarMarcasFiltro();
            marcaFiltro.getMarcaFiltro().then(data => setProducts(data)); 
        } else {
            class CargarMarcasFiltro{
                getMarcaFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarmarca=1&marca='+event.target.value+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }        
            const marcaFiltro = new CargarMarcasFiltro();
            marcaFiltro.getMarcaFiltro().then(data => setProducts(data)); 
        }
        
    }  

    const handleChangeRubro = (event) => {    
        //campoBusquedaDeshabilitar();
        localStorage.setItem('rubrofiltrado', event.target.value)
        if(localStorage.getItem('marcafiltrada') !== "1" && localStorage.getItem('rubrofiltrado') !== "1"){
            class CargarMarcasFiltro{
                getMarcaFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarambos=1&rubroambos='+event.target.value+'&marcaambos='+localStorage.getItem('marcafiltrada')+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }        
            const marcaFiltro = new CargarMarcasFiltro();
            marcaFiltro.getMarcaFiltro().then(data => setProducts(data)); 
        } else if(localStorage.getItem('marcafiltrada') === "1" && localStorage.getItem('rubrofiltrado') !== "1") {
            class CargarRubrosFiltro{
                getRubroFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarrubro=1&rubro='+event.target.value+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }
            const rubroFiltro = new CargarRubrosFiltro();
            rubroFiltro.getRubroFiltro().then(data => setProducts(data));
        } else if(localStorage.getItem('rubrofiltrado') === "1" && localStorage.getItem('marcafiltrada') !== "1"){
            class CargarMarcasFiltro{
                getMarcaFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarambos=1&rubroambos='+event.target.value+'&marcaambos='+localStorage.getItem('marcafiltrada')+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }        
            const marcaFiltro = new CargarMarcasFiltro();
            marcaFiltro.getMarcaFiltro().then(data => setProducts(data)); 
        } else {
            class CargarRubrosFiltro{
                getRubroFiltro() {
                    return axios.get(UrlGral+'productos/?filtrarrubro=1&rubro='+event.target.value+'&llave='+localStorage.getItem('llave')).then(
                        res => res.data,
                        campoBusquedaHabilitar()
                        );
                }
            }
            const rubroFiltro = new CargarRubrosFiltro();
            rubroFiltro.getRubroFiltro().then(data => setProducts(data));
        }
    }      

    const showInfo = () => {
        toast.current.show({ severity: 'success', summary: 'Agregado', detail: 'Producto agregado correctamente', life: 5000 });
    };

    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'NO SE AGREGÓ', detail: 'Hubo un problema y no se pudo agregar el producto', life: 5000 });
    };
    
    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span>{rowData.codigo}</span>
            </>
        );
    }

    const precio_sinivaBodyTemplate = (rowData) => {
        
        return (
            <>
                <span>$ {rowData.precio_siniva}</span>
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.nombre}
            </>
        );
    }

    const rubroBodyTemplate = (rowData) => {
        return (
            <>
                <span>{rowData.NombreRubro}</span>
            </>
        );
    }

    const imgBodyTemplate = (rowData) => {
        return (
            <>  
                <img src={`assets/img/productos/${rowData.img}`} alt={rowData.img} className="product-image" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span>$ {rowData.precio}</span>
            </>
        );
    }

    const brandBodyTemplate = (rowData) => {
        return (
            <>
                <span>{rowData.NombreMarca}</span> 
            </>
        );
    }


    const [datosPedido, setDatos] = useState({
        id_usuario: '',
        id_producto: '',
        cantidad: ''
    })

    const handleInputChange = (event) => {
        setDatos({
            ...datosPedido,
            [event.target.name] : event.target.value
        })
    }        

    const cantidadPlantilla = (rowData) => {
        return (
            <>
                {rowData.cantidad}
            </>
        );
    }

    const codigoProductoPlantilla = (rowData) => {
        return (
            <>
                {rowData.codigo}
            </>
        );
    }

    const verDetallepedido= () =>{
        /* const itemactual = new ItemsActual();
        itemactual.getActual().then(data => setItemsActual(data)); */  
        const actualizacarrito = new ActualizaCarrito();
        actualizacarrito.getActualizaCarrito().then(console.log("ACTUALIZADA"));      
        setVisibleRight(true);
    }

    const nombreProductoPlantilla = (rowData) => {
        return (
            <>
                {rowData.nombre}
            </>
        );
    }

    const precioPlantilla = (rowData) => {
        return (
            <>
                $ {rowData.precio}
            </>
        );
    }

    let total = 0;
    {itemsactual !== null &&
        itemsactual.map((item) => (                             
            total += parseFloat(item.subtotal)
        ))
    }  

    const subtotalPlantilla = (rowData) => {
        return (
            <>
                $ {rowData.subtotal}
            </>
        );
    }

    

    const marcaPlantilla = (rowData) => {
        return (
            <>
                {rowData.NombreMarca}
            </>
        );
    }

    const subtotalPlantilla2 = (rowData) => {
        return (
            <>
                Cantidad: {rowData.cantidad}<br/>Precio:<b> ${rowData.precio}</b><br/>Subtotal: ${rowData.subtotal}
            </>
        );
    }

    const marcaPlantilla2= (rowData) => {
        return (
            <>
               Código: {rowData.codigo}<br/>Marca:<b>{rowData.NombreMarca}</b><br/>Detalle: {rowData.nombre}
            </>
        );
    }

    const EliminarItemPlantilla= (rowData) => {
        return (
            <>
                <Button className="p-button-info botoneditaritem" onClick={() => elegirproductotabla2(rowData)} icon="pi pi-pencil" />
               <Button className="p-button-danger botoneliminaritem" onClick={() => EliminarItemPedidoActual(rowData)} icon="pi pi-trash" />               
            </>
        );
    }
    
    const EliminarItemPlantillaMobile= (rowData) => {
        return (
            <>
            
                <div className="p-grid">
                    <div className="p-col-12">
                        <Button className="p-button-info botoneditaritem" onClick={() => elegirproductotabla2(rowData)} icon="pi pi-pencil" />      
                    </div>
                    <div className="p-col-12">
                        <Button className="p-button-danger botoneliminaritem" onClick={() => EliminarItemPedidoActual(rowData)} icon="pi pi-trash" />     
                    </div>
                </div>
                
                              
            </>
        );
    }

    function rojo() {
        var divacambiar = document.getElementById('contenedordatosproducto');
        divacambiar.style.transition = '0.2s'
        setTimeout(divacambiar.style.boxShadow = '0px 0px 10px 1px rgba(200,0,0,0.75)', 2000);
    }

    function claro() {
        var divacambiar = document.getElementById('contenedordatosproducto');
        setTimeout(divacambiar.style.boxShadow = '0px 0px 10px 1px rgba(0,0,0,0.25)', 2000);
    }

    function aspectoeleccion(){
        setTimeout(() => {
            rojo()
        }, 0);

        setTimeout(() => {
            claro()
        }, 500);
    }

    const elegirproductotabla = (product) => {        
        setProduct({ ...product.data });
        console.log(product.data)
        setDatos({
            ...datosPedido,
            cantidad: ''
        })
        if(product.data.img == ""){
            setProduct({ ...product.data,
                img: "sinimg.jpg"
            });
        } else {
            setProduct({ ...product.data,
                img: product.data.img
            });
        }
        aspectoeleccion()        
        setVisibleRight(false)
    }

    const elegirproductotabla2 = (product) => {          
        setProduct({ ...product });
        console.log(product)
        if(product.img == ""){
            setProduct({ ...product,
                img: "sinimg.jpg"
            });
        } else {
            setProduct({ ...product,
                img: product.img
            });
        }
        aspectoeleccion()
        setDatos({
            ...datosPedido,
            cantidad: product.cantidad
        })
        setVisibleRight(false)
        console.log(product);
    }
    
    var preciomandar = 0;
    const editarCantidadPedido=async()=>{        
        var id_usuario = localStorage.getItem('user_id')
        if(userLista === "3"){
            preciomandar = product.precio_siniva
        } else if(userLista === "2"){
            preciomandar = product.precio
        } else {
            preciomandar = 0;
        }
        var fpedido = new FormData();
        fpedido.append("id_producto", product.idProducto);
        fpedido.append("precio", preciomandar);
        fpedido.append("id_usuario", id_usuario);
        fpedido.append("cantidad", datosPedido.cantidad);
        fpedido.append("METHOD", "POST");
        axios.post(UrlGral+"pedidos/", fpedido)
        .then(response=>{
            if(response.data === "NO SE SELECCIONO PRODUCTO"){
                showError()
            } else {      
                const actualizacarrito = new ActualizaCarrito();
                actualizacarrito.getActualizaCarrito().then(console.log("ACTUALIZADA"));
                const itemactual = new ItemsActual();
                itemactual.getActual().then(data => setItemsActual(data));              
                showInfo() 
            }            
        }).catch(error=>{
            console.log(error);            
        })  
        setDatos({
            ...datosPedido,
            cantidad: ''
        })   
        setProduct(emptyProduct)
    }   


    const EliminarItemPedidoActual=async(rowData)=>{       
        var fpedido = new FormData();
        fpedido.append("ideliminaritem",rowData.id_itempedido);
        fpedido.append("eliminaritem", "1");
        fpedido.append("METHOD", "DELETE");
        await axios.post(UrlGral+"pedidos/", fpedido)
        .then(response=>{
            console.log(response)
        }).catch(error=>{
            console.log(error);
        })    
        const actualizacarrito = new ActualizaCarrito();
        actualizacarrito.getActualizaCarrito().then(console.log("ACTUALIZADA"));
        const itemactual = new ItemsActual();
        itemactual.getActual().then(data => setItemsActual(data)); 
        setVisibleRight(true) 
    } 

    const GuardarPedido=async()=>{
        let user_id = localStorage.getItem('user_id');
        if(!user_id){
            alert('El usuario no ha iniciado sesión');
        }else{
            var fpedido = new FormData();
            fpedido.append("id_usuario",user_id);
            fpedido.append("generar", "1");
            fpedido.append("METHOD", "POST");
            await axios.post(UrlGral+"pedidos/", fpedido)
            .then(response=>{
              console.log(response)
            }).catch(error=>{
             console.log(error);
            })

            setDisplayConfirmation(false)
            verDetallepedido();
            setVisibleRight(false);
            setDisplayConfirmationGenerado(true)
        }
      } 
    /* console.log(userLista) */
    const VaciarPedido=async()=>{
        let user_id = localStorage.getItem('user_id');
        var fpedido = new FormData();
        fpedido.append("id_usuario",user_id);
        fpedido.append("vaciar", "1");
        fpedido.append("METHOD", "POST");
        await axios.post(UrlGral+"pedidos/", fpedido)
        .then(response=>{
            console.log(response)
        }).catch(error=>{
            console.log(error);
        })
        const actualizacarrito = new ActualizaCarrito();
        actualizacarrito.getActualizaCarrito().then(console.log("ACTUALIZADA"));
        const itemactual = new ItemsActual();
        itemactual.getActual().then(data => setItemsActual(data)); 
        setDisplayConfirmationVaciar(false)
        setDisplayConfirmationVaciado(true)
        setVisibleRight(false);
    }

    const confirmationDialogFooter = (
    <>
        <Button type="button" label="Si" icon="pi pi-check" onClick={() => GuardarPedido()} className="p-button-text" />
        <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" autoFocus />
    </>
    ); 

    const confirmationDialogFooterVaciar = (
        <>
            <Button type="button" label="Si" icon="pi pi-check" onClick={() => VaciarPedido()} className="p-button-text" />
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationVaciar(false)} className="p-button-text" autoFocus />
        </>
    ); 

    
    const confirmationDialogFooterGenerado = (
        <>
            <Button type="button" label="Ok" icon="pi pi-check" path="/mispedidos" onClick={() => setDisplayConfirmationGenerado(false)} className="p-button-text" />
            <Button type="button" label="Ir a mis pedidos" icon="pi pi-arrow-right" onClick={() => IrAMisPedidos()} className="p-button-text" />
        </>
    );
    
    const confirmationDialogFooterVaciado = (
        <>
            <Button type="button" label="Ok" icon="pi pi-check" path="/mispedidos" onClick={() => setDisplayConfirmationVaciado(false)} className="p-button-text" />
        </>
    );

    const IrAMisPedidos=async()=>{
        setDisplayConfirmationGenerado(false)
        setRedirect('/mispedidos');        
    }

    if(redirect && redirect != "/catalogo"){
        return <Redirect to={redirect}/>
    }

    return (
        <div className="p-grid crud-demo">
            <Button type="button" className="p-button-warning verdetallepedidoactual verescritorio" onClick={() => verDetallepedido()}>Ver pedido<i className="pi pi-shopping-cart p-c" style={{ fontSize: '2rem' }}></i></Button>    
            <Sidebar className="detallespedidosidebar" style={{height: '100%'},{overflow: 'scroll'}} visible={visibleRight} onHide={() => setVisibleRight(false)} baseZIndex={500} position="right">
                <div className="p-grid">
                    <div className="p-col-4 verescritorio">
                        <h4 style={{ fontWeight: 'normal' }}>Mi pedido</h4>
                    </div>
                    <div className="p-col-12 vermobile topmenos50">
                        <h5 style={{ fontWeight: 'normal'}}>Mi pedido</h5>
                    </div>
                    <div className="p-col-8 verescritorio" style={{textAlign: 'right'}}>
                        <Button type="button" onClick={() => setDisplayConfirmation(true)} label="Generar Pedido" className="p-button-success" style={{marginRight: '10px'}} />
                        <Button type="button" onClick={() => setDisplayConfirmationVaciar(true)} label="Vaciar Pedido" className="p-button-danger vaciarpedido" style={{marginRight: '100px'}} />    
                    </div>                   
                    <div className="p-col-6 vermobile" >
                        <Button style={{ width: '90%' }} type="button" onClick={() => setDisplayConfirmation(true)} label="Generar Pedido" className="p-button-success"/>
                    </div>
                    <div className="p-col-6 vermobile" >
                        <Button style={{ width: '90%' }} type="button" onClick={() => setDisplayConfirmationVaciar(true)} label="Vaciar Pedido" className="p-button-danger vaciarpedido"/>    
                    </div>                  
                </div>
                
                <div className="verescritorio">
                    <DataTable 
                    ref={dt} 
                    value={itemsactual} 
                    selection={selectedItemsActual} 
                    onSelectionChange={(e) => setSelectedItemsActual(e.value)}
                    dataKey="idProducto" 
                    className="datatable"
                    /* onRowClick={} */
                    footer={
                        <div className="p-grid">
                            <div className="p-col-8">
                            </div>
                            <div className="p-col-4">
                            Total pedido: ${total}
                            </div>
                        </div> 
                    }
                    paginator rows={10} rowsPerPageOptions={[2, 5, 10]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} a {last} de {totalRecords} items"
                    emptyMessage="Sin items en el pedido.">
                        <Column className="datoitemactual w-70px" field="codigo_producto" header="Código" body={codigoProductoPlantilla}></Column> 
                        <Column className="datoitemactual w-80px" field="marca" header="Marca" body={marcaPlantilla}></Column>   
                        <Column className="datoitemactual" field="nombre" header="Nombre" body={nombreProductoPlantilla}></Column>      
                        <Column className="datoitemactual w-70px" field="precio" header="Precio" body={precioPlantilla}></Column>
                        <Column className="datoitemactual w-70px" field="cantidad" header="Cantidad" body={cantidadPlantilla}></Column>      
                        <Column className="datoitemactual w-70px" field="subtotal" header="Subtotal" body={subtotalPlantilla}></Column>
                        <Column className="datoitemactual w-100px t-a-left" field="eliminarItem" header="" body={EliminarItemPlantilla}></Column>                       
                    </DataTable>
                </div>
                <div className="vermobile">
                    <DataTable ref={dt} value={itemsactual} selection={selectedItemsActual} onSelectionChange={(e) => setSelectedItemsActual(e.value)}
                    dataKey="idProducto" className="datatable"
                    onRowClick={elegirproductotabla}
                    currentPageReportTemplate="{first} de {last} total {totalRecords}"
                    emptyMessage="Sin items en el pedido.">
                        <Column className="datoitemactual" field="marca" header="Producto" body={marcaPlantilla2}></Column>     
                        <Column className="datoitemactual" field="subtotal" header="Subtotal" body={subtotalPlantilla2}></Column>
                        <Column className="datoitemactual eliminaritem" field="eliminarItem" header="Eliminar" body={EliminarItemPlantillaMobile}></Column>
                    </DataTable>
                </div>   
            </Sidebar>    
            <Dialog header="Generar pedido" baseZIndex={1001} visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                <div className="confirmation-content confirmapedido">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Está seguro que quiere generar el pedido?</span>
                </div>
            </Dialog>
            <Dialog header="Vaciar pedido" baseZIndex={1001} visible={displayConfirmationVaciar} onHide={() => setDisplayConfirmationVaciar(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterVaciar}>
                <div className="confirmation-content confirmapedido">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Está seguro que quiere vaciar el pedido?</span>
                </div>
            </Dialog>
            <Dialog header="Pedido generado." baseZIndex={1001} visible={displayConfirmationGenerado} onHide={() => setDisplayConfirmationGenerado(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterGenerado}>
                <div className="confirmation-content confirmapedido">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Tu pedido se generó correctamente.</span>
                </div>
            </Dialog>   
            <Dialog header="Vaciar pedido." baseZIndex={1001} visible={displayConfirmationVaciado} onHide={() => setDisplayConfirmationVaciado(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterVaciado}>
                <div className="confirmation-content confirmapedido">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Tu pedido se ha vaciado correctamente.</span>
                </div>
            </Dialog>            
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">     
                        <div className="p-grid">              
                            <div className="p-col-6 p-lg-3 camposbusqueda">  
                                {/* style={{background: 'url("assets/img/marcas/3m-logo.jpg") no-repeat center left', backgroundSize: 'cover', paddingLeft: '20px', height: '100px, width: '50px'}   */}                
                                <select value={localStorage.getItem('marcafiltrada')} onChange={handleChangeMarca} id="selectmarca"> 
                                    <option value="0">Todas las marcas</option>
                                    {marcas !== null &&
                                        marcas.filter(marca => marca.nombre != "").map((marca) => (                                        
                                            <option 
                                            
                                            value={marca.id}
                                            >                 
                                                
                                                {marca.nombre }
                                            
                                            </option>
                                        ))
                                    }                               
                                </select>  
                            </div>                
                            <div className="p-col-6 p-lg-3 camposbusqueda">
                                <select value={localStorage.getItem('rubrofiltrado')} onChange={handleChangeRubro} id="selectrubro"> 
                                    <option value="0">Todos los rubros</option>   
                                    {rubros !== null &&
                                        rubros.filter(rubro => rubro.nombre != "").map((rubro) => (                                        
                                            <option
                                            value={rubro.id}
                                            >
                                                {rubro.nombre}
                                            </option>
                                        ))
                                    }                                 
                                </select>
                            </div>
                            <div className="p-col-9 p-lg-3 camposbusqueda">
                                <span className="p-input-icon-left inputbusc">                
                                    <i className="pi pi-search" id="iconobuscar"/>
                                    <InputText onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar" id="buscadorporcodigo"/>
                                </span>
                            </div>
                            <div className="p-col-3 camposbusqueda">
                                <Button type="button" className="p-button-warning verdetallepedidoactual2 vermobile" onClick={() => verDetallepedido()}>Ver<i className="pi pi-shopping-cart p-c" style={{ fontSize: '1rem' }}></i></Button>  
                            </div>
                        </div>
                        <div className="p-col-12">                                              
                        <Toast ref={toast} />
                        </div>       
                        <div className="verescritorio">           
                            <DataTable 
                                ref={dt} 
                                value={products}
                                dataKey="idProducto" 
                                paginator rows={10} 
                                rowsPerPageOptions={[5, 10, 25]} 
                                className="tablaproductos"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="De {first} a {last} de {totalRecords} productos"
                                onRowClick={elegirproductotabla}                   
                                globalFilter={globalFilter1} 
                                emptyMessage="No se encontraron productos" 
                                /* header={customer1TableHeader} */>                                
                                <Column className="datoproducto" style={{ width: '10%' }} field="NombreMarca" header="Marca" sortable body={brandBodyTemplate}></Column>
                                <Column className="datoproducto" style={{ width: '10%' }} field="NombreRubro" header="Rubro" sortable body={rubroBodyTemplate}></Column>
                                <Column className="datoproducto" style={{ width: '10%' }} field="codigo" header="Codigo" sortable body={codeBodyTemplate}></Column>
                                <Column className="datoproducto" style={{ width: '40%' }} field="nombre" header="Producto" sortable body={nameBodyTemplate}></Column>                                
                                <Column className="datoproducto" style={{ width: '15%' }} field="precio_siniva" header="Precio sin IVA" body={precio_sinivaBodyTemplate} sortable></Column>
                                <Column className="datoproducto" style={{ width: '15%' }} field="precio" header="Precio Final" body={priceBodyTemplate} sortable></Column>                                
                            </DataTable>   
                        </div>
                        <div className="vermobile">           
                            <DataTable 
                                ref={dt} 
                                value={products}
                                dataKey="idProducto" 
                                paginator rows={10} 
                                className="tablaproductos table table-striped"
                                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="De {first} a {last} de {totalRecords} productos"
                                onRowClick={elegirproductotabla}                   
                                globalFilter={globalFilter1} 
                                emptyMessage="No se encontraron productos" 
                                /* header={customer1TableHeader} */>                                
                                <Column className="datoproducto" style={{ width: '20%' }} field="NombreMarca" header="Marca" sortable body={brandBodyTemplate}></Column>
                                <Column className="datoproducto" style={{ width: '25%' }} field="codigo" header="Codigo" sortable body={codeBodyTemplate}></Column>
                                <Column className="datoproducto" style={{ width: '55%' }} field="nombre" header="Nombre" sortable body={nameBodyTemplate}></Column>
                            </DataTable>   
                        </div>
                    </div>                    
                    <div className="p-col-12 verescritorio"> 
                        <div visible={productDialog}>
                            <div id="contenedordatosproducto" className="p-grid contenedordatosproducto">
                                <div className="p-col-3">
                                    <div className="box">
                                        <img 
                                        src={`assets/img/productos/`+product.img} 
                                        alt={product.img} 
                                        className="img-producto" />
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">Marca: <b>{product.NombreMarca}</b></div>
                                        <div className="p-col-12">Detalles: <b>{product.nombre}</b> - <b>{product.descripcion}</b></div>
                                        <div className="p-col-12">Rubro: {product.NombreRubro}</div>
                                        {/* <div className="p-col-12">Precio: <b>${product.precio}</b></div> */}
                                        <div className="p-col-12">Precio: <b>
                                        { userLista === "3" 
                                            ? "$"+product.precio_siniva
                                            : userLista === "2"
                                            ? "$"+product.precio
                                            : "Consultar preico"
                                            }
                                            
                                        </b></div>
                                    </div>
                                </div>
                                <div className="p-col-3">
                                    <div className="p-grid">
                                        <input type="hidden" id="id_producto" name="id_producto" value={product.idProducto}/>
                                        <input type="hidden" id="precio" name="precio" value={                                            
                                             userLista === "3" 
                                            ? "$"+product.precio_siniva
                                            : userLista === "2"
                                            ? "$"+product.precio
                                            : "Consultar preico"                                            
                                        }/>
                                        <div className="p-md-12 p-lg-12">Cantidad:</div>
                                        <div className="p-md-12 p-lg-6"><InputText name="cantidad" autoComplete="off" value={datosPedido.cantidad} onChange={handleInputChange}/></div>
                                        <div className="p-md-12 p-lg-6">
                                            <Button className="p-button p-button-info p-mr-2 botoncantidad" onClick={editarCantidadPedido}>Agregar</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 vermobile"> 
                        <div visible={productDialog}>
                            <div className="p-grid">                                
                                <div className="p-col-6">
                                    <div className="p-grid">
                                        <div className="p-col-12 detalleproductomobile">Marca: <b>{product.NombreMarca}</b></div>
                                        <div className="p-col-12 detalleproductomobile">Detalles: <b>{product.nombre}</b></div>
                                        {/* <div className="p-col-12 detalleproductomobile">Rubro: {product.rubro}</div> */}
                                        <div className="p-col-12 detalleproductomobile">Precio: <b>$
                                            
                                        { userLista === "3" 
                                            ? "$"+product.precio_siniva
                                            : userLista === "2"
                                            ? "$"+product.precio
                                            : "Consultar preico"
                                            }
                                            </b></div>
                                    </div>
                                </div>    
                                <div className="p-col-6">
                                    <div className="p-grid">
                                        <input type="hidden" id="id_producto" name="id_producto" value={product.idProducto}/>
                                        <input type="hidden" id="precio" name="precio" value=
                                        { userLista === "3" 
                                        ? "$"+product.precio_siniva
                                        : userLista === "2"
                                        ? "$"+product.precio
                                        : "Consultar preico"
                                        }
                                        />
                                        <div className="p-col-12 p-md-3 cantidadagregar"><InputText autoComplete="off" name="cantidad" value={product.cantidad} onChange={handleInputChange} placeholder="Cantidad"/></div>
                                        <div className="p-col-12 p-md-12 cantidadagregar">
                                            <Button className="p-button p-button-info p-mr-2 botoncantidad" onClick={editarCantidadPedido} label="Agregar"></Button>
                                        </div>
                                    </div>
                                </div> 
                                <div className="p-col-6">
                                    <div className="box">
                                        <img 
                                        src={`assets/img/productos/`+product.img} 
                                        alt={product.img} 
                                        className="img-producto" />
                                    </div>
                                </div>                                                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
